import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Customizing a ClientFactory with ClientFactoryBuilder";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "customizing-a-clientfactory-with-clientfactorybuilder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#customizing-a-clientfactory-with-clientfactorybuilder",
        "aria-label": "customizing a clientfactory with clientfactorybuilder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing a `}<inlineCode parentName="h1">{`ClientFactory`}</inlineCode>{` with `}<inlineCode parentName="h1">{`ClientFactoryBuilder`}</inlineCode></h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#customizing-tls"
        }}>{`Customizing TLS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#lifecycle-of-clientfactory"
        }}>{`Lifecycle of ClientFactory`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#specifying-netty-channeloptions"
        }}>{`Specifying Netty ChannelOptions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#specifying-different-eventloop-threads-for-different-endpoints"
        }}>{`Specifying different EventLoop threads for different endpoints`}</a></li>
    </ul>
    <p>{`A `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` manages connections and protocol-specific properties.
You can customize the properties via `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html"
      }}>{`type://ClientFactoryBuilder`}</a>{`. For example,
let's say that you want to increase the connection timeout.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ClientFactory factory =
  ClientFactory
    .builder()
    // Increase the connection timeout to 5 seconds.
    .connectTimeoutMillis(5000)
    .build());
WebClient client =
  WebClient
   .builder("https://armeria.dev")
   .factory(factory)
   .build();
client.get("/api").aggregate().join();
`}</code></pre>
    <p>{`The client created with the given `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` will respect the settings such as connection timeout
that you defined.
You might notice that the clients, which are created using the same `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{`,
share one connection pool. If you don't want to share the connections among the clients,
you should use different `}<a parentName="p" {...{
        "href": "typeplural://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`typeplural://ClientFactory`}</a>{`.
For the complete list of properties, see `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html"
      }}>{`type://ClientFactoryBuilder`}</a>{`.`}</p>
    <Tip mdxType="Tip">
      <p>{`  You might want to take a look at `}<a parentName="p" {...{
          "href": "type://ClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html"
        }}>{`type://ClientBuilder`}</a>{` to distinguish the client-specific properties.`}</p>
    </Tip>
    <p>{`You can also use `}<a parentName="p" {...{
        "href": "type://ClientFactoryOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryOptions.html"
      }}>{`type://ClientFactoryOptions`}</a>{` to build `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{`.
It's more verbose than calling a dedicated setter method, but it can be useful when you need to
set a series of options programmatically.`}</p>
    <h2 {...{
      "id": "customizing-tls",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#customizing-tls",
        "aria-label": "customizing tls permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing TLS`}</h2>
    <p>{`You can configure the client authentication
for `}<a parentName="p" {...{
        "href": "https://www.cloudflare.com/learning/access-management/what-is-mutual-tls/"
      }}>{`mutual TLS (mTLS)`}</a>{`
using `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder#tls(InputStream,InputStream):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#tls(java.io.InputStream,java.io.InputStream)"
      }}>{`type://ClientFactoryBuilder#tls(InputStream,InputStream)`}</a>{` .
Additionally, you can specify various options such as a different CA certificate chain or enforce certain
cipher suites with `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder#tlsCustomizer(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#tlsCustomizer(java.util.function.Consumer)"
      }}>{`type://ClientFactoryBuilder#tlsCustomizer(Consumer)`}</a>{` .`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`try (InputStream keyCertChainInputStream = ...;
     InputStream keyInputStream = ...) {
  ClientFactory factory =
    ClientFactory
      .builder()
      .tls(keyCertChainInputStream, keyInputStream)
      .build();
  WebClient
    .builder()
    .factory(factory)
    .build();
}
`}</code></pre>
    <h2 {...{
      "id": "lifecycle-of-clientfactory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lifecycle-of-clientfactory",
        "aria-label": "lifecycle of clientfactory permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lifecycle of `}<inlineCode parentName="h2">{`ClientFactory`}</inlineCode></h2>
    <p>{`Most clients for a `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` need to send requests to another server for the lifetime of an application.
Therefore, you will generally avoid closing the `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{`, except for perhaps in a shutdown hook,
to avoid losing any connection to the other servers.
For unit tests where a `}<a parentName="p" {...{
        "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
      }}>{`type://ClientFactory`}</a>{` is only used for a single test or test class,
it is appropriate to close it when done using it.`}</p>
    <h2 {...{
      "id": "specifying-netty-channeloptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#specifying-netty-channeloptions",
        "aria-label": "specifying netty channeloptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifying Netty `}<inlineCode parentName="h2">{`ChannelOption`}</inlineCode>{`s`}</h2>
    <p>{`You can also specify Netty `}<a parentName="p" {...{
        "href": "https://netty.io/4.1/api/io/netty/channel/ChannelOption.html"
      }}><inlineCode parentName="a">{`ChannelOption`}</inlineCode></a>{`
via `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder#channelOption(ChannelOption,T):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#channelOption(io.netty.channel.ChannelOption,T)"
      }}>{`type://ClientFactoryBuilder#channelOption(ChannelOption,T)`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ClientFactory factory =
  ClientFactory
    .builder()
    // Set the size of send socket buffer as 4096 bytes
    .channelOption(ChannelOption.SO_SNDBUF, 4096)
    .build();
WebClient
  .builder()
  .factory(factory)
  .build();
`}</code></pre>
    <h2 {...{
      "id": "specifying-different-eventloop-threads-for-different-endpoints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#specifying-different-eventloop-threads-for-different-endpoints",
        "aria-label": "specifying different eventloop threads for different endpoints permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Specifying different `}<inlineCode parentName="h2">{`EventLoop`}</inlineCode>{` threads for different endpoints`}</h2>
    <p>{`By default, Armeria keeps at most 1 connection per endpoint (host and port pair) when using HTTP/2,
which is desirable for typical HTTP/2 services. Armeria achieves this by assigning one `}<inlineCode parentName="p">{`EventLoop`}</inlineCode>{` per endpoint
from the `}<a parentName="p" {...{
        "href": "type://ServerConfig#workerGroup():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerConfig.html#workerGroup()"
      }}>{`type://ServerConfig#workerGroup()`}</a>{`. However, there's a limit to the requests that
a single connection(or `}<inlineCode parentName="p">{`EventLoop`}</inlineCode>{` which is a thread) can handle, so you might want to increase
the number of connections(or `}<inlineCode parentName="p">{`EventLoop`}</inlineCode>{` threads) for certain or all endpoints.`}</p>
    <p>{`You can do this via `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder#maxNumEventLoopsPerEndpoint(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#maxNumEventLoopsPerEndpoint(int)"
      }}>{`type://ClientFactoryBuilder#maxNumEventLoopsPerEndpoint(int)`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ClientFactory factory =
  ClientFactory
    .builder()
    // A client will use maximum 5 EventLoops from the worker group per endpoint
    .maxNumEventLoopsPerEndpoint(5)
    .build();
WebClient
  .builder()
  .factory(factory)
  .build();

ClientFactory factory =
  ClientFactory
    .builder()
    // or you can just use Integer.MAX_VALUE to use all EventLoops
    .maxNumEventLoopsPerEndpoint(Integer.MAX_VALUE)
    .build();
WebClient
  .builder()
  .factory(factory)
  .build();

ClientFactory factory =
  ClientFactory
    .builder()
    // A client will use maximum 5 EventLoops per HTTP/1.1 endpoint
    .maxNumEventLoopsPerHttp1Endpoint(5)
    .build();
`}</code></pre>
    <p>{`The client might need to send many requests to a certain endpoint, while sending small number of requests to others.
In that situation, you can use `}<a parentName="p" {...{
        "href": "type://ClientFactoryBuilder#maxNumEventLoopsFunction(ToIntFunction):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#maxNumEventLoopsFunction(java.util.function.ToIntFunction)"
      }}>{`type://ClientFactoryBuilder#maxNumEventLoopsFunction(ToIntFunction)`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ClientFactory factory =
  ClientFactory
    .builder()
    .maxNumEventLoopsFunction(endpoint -> {
      if (endpoint.equals(Endpoint.of("lotsOfTraffic.com"))) {
        // We are going to use all EventLoops to send requests.
        return Integer.MAX_VALUE;
      }
      // We use just one \`EventLoop\` per endpoint for the rest.
      return 1;
    })
    .build();
WebClient
  .builder()
  .factory(factory)
  .build();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      